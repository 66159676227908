import { HistoryLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import ReactMarkdown from 'react-markdown'
import { Col, Container, Image, Row } from "react-bootstrap";
import rehypeReact from "rehype-react";
import { unified } from "unified";
import CtaButtons from "../components/CtaButtons";
import Layout from "../components/layout";
import Seo from "../components/Seo";

interface Props {
  location: HistoryLocation;
  data: any;
}

// @ts-ignore
const processor = unified().use(rehypeReact, {
  createElement: React.createElement,
  components: { "cta-buttons": CtaButtons },
});
export const renderAst = (ast: any): JSX.Element => {
  return (processor.stringify(ast) as unknown) as JSX.Element
};

interface IndexCol {
  icon: string,
  title: string,
  subtitle: string,
  content:  string,
  boxpic: string,
}

const colStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const IndexPage = ({ location, data }: Props) => {

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  // @ts-ignore
  const { frontmatter, htmlAst } = markdownRemark;
  const olumns: IndexCol[] = frontmatter.columns;

  const cols = olumns.map(({ icon, title, subtitle, content, boxpic }, index) => (
    <Col sm={12} md={4} key={index + Math.random()} style={colStyle} >
      <div className="fp-box" >
        <div className="col-head fp-margins" >
            <h4>{title}</h4>
             <Image src={icon} />
             <h5 className="subheading">
              {subtitle}
            </h5>
          </div>
          <ReactMarkdown className= "fp-margins justify text-center">
            {content}
          </ReactMarkdown>
      </div>
      <div style={{marginTop: 'auto'}}>
        <Image src={boxpic} width="100%" />
      </div>
    </Col>
  ));


  return (
    <Layout location={location} title={""}>
      <Seo title={"Execute your Environmental Impact in Real Time"} />

        <Container fluid={true} className="leafs">
          <Row className="bg-cta">
            <Col md={{ span: 6, offset: 3 }} className="dimmed">
              <div>
                <h1>
                  LEAD<br /> SUSTAINABILITY<br /> WITH&nbsp;DATA
                </h1>
              </div>
              <div className="bg-logo">
                <Image fluid={true} src="/verderon-logo.png" width="338" height="170" alt="Verderon" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="fp-subtitle justify">
          <h5 className="text-center">
          Verderon calculates your environmental impact on a daily basis using data sources such as process measurements, historian databases, and enterprise resource planning systems (ERP). This provides a better and more reliable basis for environmental improvements and decision-making. Continuous data availability can be used to verify each step on the path towards your sustainability goals.
          </h5>
        </Container>
        <Container>
          <Row>
            {cols}
          </Row>
        </Container>
    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      htmlAst
      frontmatter {
        title
        description
        slug
        keywords
        className
        index
        columns {
          title
          icon
          subtitle
          content
          boxpic
        }
      }
    }
  }
`;
